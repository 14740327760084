export const RACKDATA = [
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-01",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 0.723,
        "x1": 1.336,
        "y1": 1.923,
        "name": "Rack1"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-02",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 0.723,
        "x1": 2.965,
        "y1": 1.923,
        "name": "Rack2"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-03",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 0.723,
        "x1": 4.594,
        "y1": 1.923,
        "name": "Rack3"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-04",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 0.723,
        "x1": 6.223,
        "y1": 1.923,
        "name": "Rack4"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-05",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 0.723,
        "x1": 7.852,
        "y1": 1.923,
        "name": "Rack5"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-06",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 0.723,
        "x1": 9.481,
        "y1": 1.923,
        "name": "Rack6"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-07",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 0.723,
        "x1": 11.11,
        "y1": 1.923,
        "name": "Rack7"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-08",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 0.723,
        "x1": 12.739,
        "y1": 1.923,
        "name": "Rack8"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-09",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 0.723,
        "x1": 14.368,
        "y1": 1.923,
        "name": "Rack9"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 0.723,
        "x1": 15.997,
        "y1": 1.923,
        "name": "Rack10"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 0.723,
        "x1": 17.626,
        "y1": 1.923,
        "name": "Rack11"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 0.723,
        "x1": 19.255,
        "y1": 1.923,
        "name": "Rack12"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 0.723,
        "x1": 20.884,
        "y1": 1.923,
        "name": "Rack13"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 0.723,
        "x1": 22.513,
        "y1": 1.923,
        "name": "Rack14"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-0F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 0.723,
        "x1": 24.142,
        "y1": 1.923,
        "name": "Rack15"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-10",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 0.723,
        "x1": 25.771,
        "y1": 1.923,
        "name": "Rack16"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-11",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 0.723,
        "x1": 27.4,
        "y1": 1.923,
        "name": "Rack17"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-12",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 2.2,
        "x1": 1.336,
        "y1": 3.4,
        "name": "Rack18"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-13",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 2.2,
        "x1": 2.965,
        "y1": 3.4,
        "name": "Rack19"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-14",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 2.2,
        "x1": 4.594,
        "y1": 3.4,
        "name": "Rack20"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-15",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 2.2,
        "x1": 6.223,
        "y1": 3.4,
        "name": "Rack21"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-16",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 2.2,
        "x1": 7.852,
        "y1": 3.4,
        "name": "Rack22"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-17",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 2.2,
        "x1": 9.481,
        "y1": 3.4,
        "name": "Rack23"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-18",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 2.2,
        "x1": 11.11,
        "y1": 3.4,
        "name": "Rack24"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-19",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 2.2,
        "x1": 12.739,
        "y1": 3.4,
        "name": "Rack25"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 2.2,
        "x1": 14.368,
        "y1": 3.4,
        "name": "Rack26"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 2.2,
        "x1": 15.997,
        "y1": 3.4,
        "name": "Rack27"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 2.2,
        "x1": 17.626,
        "y1": 3.4,
        "name": "Rack28"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 2.2,
        "x1": 19.255,
        "y1": 3.4,
        "name": "Rack29"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 2.2,
        "x1": 20.884,
        "y1": 3.4,
        "name": "Rack30"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-1F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 2.2,
        "x1": 22.513,
        "y1": 3.4,
        "name": "Rack31"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-20",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 2.2,
        "x1": 24.142,
        "y1": 3.4,
        "name": "Rack32"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-21",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 2.2,
        "x1": 25.771,
        "y1": 3.4,
        "name": "Rack33"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-22",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 2.2,
        "x1": 27.4,
        "y1": 3.4,
        "name": "Rack34"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-23",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 4.099,
        "x1": 1.336,
        "y1": 5.299,
        "name": "Rack35"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-24",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 4.099,
        "x1": 2.965,
        "y1": 5.299,
        "name": "Rack36"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-25",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 4.099,
        "x1": 4.594,
        "y1": 5.299,
        "name": "Rack37"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-26",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 4.099,
        "x1": 6.223,
        "y1": 5.299,
        "name": "Rack38"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-27",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 4.099,
        "x1": 7.852,
        "y1": 5.299,
        "name": "Rack39"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-28",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 4.099,
        "x1": 9.481,
        "y1": 5.299,
        "name": "Rack40"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-29",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 4.099,
        "x1": 11.11,
        "y1": 5.299,
        "name": "Rack41"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 4.099,
        "x1": 12.739,
        "y1": 5.299,
        "name": "Rack42"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 4.099,
        "x1": 14.368,
        "y1": 5.299,
        "name": "Rack43"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 4.099,
        "x1": 15.997,
        "y1": 5.299,
        "name": "Rack44"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 4.099,
        "x1": 17.626,
        "y1": 5.299,
        "name": "Rack45"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 4.099,
        "x1": 19.255,
        "y1": 5.299,
        "name": "Rack46"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-2F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 4.099,
        "x1": 20.884,
        "y1": 5.299,
        "name": "Rack47"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-30",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 4.099,
        "x1": 22.513,
        "y1": 5.299,
        "name": "Rack48"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-31",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 4.099,
        "x1": 24.142,
        "y1": 5.299,
        "name": "Rack49"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-32",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 4.099,
        "x1": 25.771,
        "y1": 5.299,
        "name": "Rack50"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-33",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 4.099,
        "x1": 27.4,
        "y1": 5.299,
        "name": "Rack51"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-34",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 5.576,
        "x1": 1.336,
        "y1": 6.776,
        "name": "Rack52"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-35",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 5.576,
        "x1": 2.965,
        "y1": 6.776,
        "name": "Rack53"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-36",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 5.576,
        "x1": 4.594,
        "y1": 6.776,
        "name": "Rack54"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-37",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 5.576,
        "x1": 6.223,
        "y1": 6.776,
        "name": "Rack55"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-38",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 5.576,
        "x1": 7.852,
        "y1": 6.776,
        "name": "Rack56"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-39",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 5.576,
        "x1": 9.481,
        "y1": 6.776,
        "name": "Rack57"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 5.576,
        "x1": 11.11,
        "y1": 6.776,
        "name": "Rack58"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 5.576,
        "x1": 12.739,
        "y1": 6.776,
        "name": "Rack59"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 5.576,
        "x1": 14.368,
        "y1": 6.776,
        "name": "Rack60"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 5.576,
        "x1": 15.997,
        "y1": 6.776,
        "name": "Rack61"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 5.576,
        "x1": 17.626,
        "y1": 6.776,
        "name": "Rack62"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-3F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 5.576,
        "x1": 19.255,
        "y1": 6.776,
        "name": "Rack63"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-40",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 5.576,
        "x1": 20.884,
        "y1": 6.776,
        "name": "Rack64"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-41",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 5.576,
        "x1": 22.513,
        "y1": 6.776,
        "name": "Rack65"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-42",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 5.576,
        "x1": 24.142,
        "y1": 6.776,
        "name": "Rack66"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-43",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 5.576,
        "x1": 25.771,
        "y1": 6.776,
        "name": "Rack67"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-44",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 5.576,
        "x1": 27.4,
        "y1": 6.776,
        "name": "Rack68"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-45",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 7.476,
        "x1": 1.336,
        "y1": 8.676,
        "name": "Rack69"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-46",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 7.476,
        "x1": 2.965,
        "y1": 8.676,
        "name": "Rack70"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-47",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 7.476,
        "x1": 4.594,
        "y1": 8.676,
        "name": "Rack71"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-48",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 7.476,
        "x1": 6.223,
        "y1": 8.676,
        "name": "Rack72"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-49",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 7.476,
        "x1": 7.852,
        "y1": 8.676,
        "name": "Rack73"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 7.476,
        "x1": 9.481,
        "y1": 8.676,
        "name": "Rack74"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 7.476,
        "x1": 11.11,
        "y1": 8.676,
        "name": "Rack75"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 7.476,
        "x1": 12.739,
        "y1": 8.676,
        "name": "Rack76"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 7.476,
        "x1": 14.368,
        "y1": 8.676,
        "name": "Rack77"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 7.476,
        "x1": 15.997,
        "y1": 8.676,
        "name": "Rack78"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-4F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 7.476,
        "x1": 17.626,
        "y1": 8.676,
        "name": "Rack79"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-50",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 7.476,
        "x1": 19.255,
        "y1": 8.676,
        "name": "Rack80"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-51",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 7.476,
        "x1": 20.884,
        "y1": 8.676,
        "name": "Rack81"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-52",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 7.476,
        "x1": 22.513,
        "y1": 8.676,
        "name": "Rack82"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-53",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 7.476,
        "x1": 24.142,
        "y1": 8.676,
        "name": "Rack83"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-54",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 7.476,
        "x1": 25.771,
        "y1": 8.676,
        "name": "Rack84"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-55",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 7.476,
        "x1": 27.4,
        "y1": 8.676,
        "name": "Rack85"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-56",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 8.953,
        "x1": 1.336,
        "y1": 10.153,
        "name": "Rack86"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-57",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 8.953,
        "x1": 2.965,
        "y1": 10.153,
        "name": "Rack87"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-58",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 8.953,
        "x1": 4.594,
        "y1": 10.153,
        "name": "Rack88"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-59",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 8.953,
        "x1": 6.223,
        "y1": 10.153,
        "name": "Rack89"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 8.953,
        "x1": 7.852,
        "y1": 10.153,
        "name": "Rack90"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 8.953,
        "x1": 9.481,
        "y1": 10.153,
        "name": "Rack91"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 8.953,
        "x1": 11.11,
        "y1": 10.153,
        "name": "Rack92"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 8.953,
        "x1": 12.739,
        "y1": 10.153,
        "name": "Rack93"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 8.953,
        "x1": 14.368,
        "y1": 10.153,
        "name": "Rack94"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-5F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 8.953,
        "x1": 15.997,
        "y1": 10.153,
        "name": "Rack95"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-60",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 8.953,
        "x1": 17.626,
        "y1": 10.153,
        "name": "Rack96"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-61",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 8.953,
        "x1": 19.255,
        "y1": 10.153,
        "name": "Rack97"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-62",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 8.953,
        "x1": 20.884,
        "y1": 10.153,
        "name": "Rack98"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-63",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 8.953,
        "x1": 22.513,
        "y1": 10.153,
        "name": "Rack99"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-64",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 8.953,
        "x1": 24.142,
        "y1": 10.153,
        "name": "Rack100"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-65",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 8.953,
        "x1": 25.771,
        "y1": 10.153,
        "name": "Rack101"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-66",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 8.953,
        "x1": 27.4,
        "y1": 10.153,
        "name": "Rack102"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-67",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 10.783,
        "x1": 1.336,
        "y1": 11.983,
        "name": "Rack103"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-68",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 10.783,
        "x1": 2.965,
        "y1": 11.983,
        "name": "Rack104"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-69",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 10.783,
        "x1": 4.594,
        "y1": 11.983,
        "name": "Rack105"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 10.783,
        "x1": 6.223,
        "y1": 11.983,
        "name": "Rack106"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 10.783,
        "x1": 7.852,
        "y1": 11.983,
        "name": "Rack107"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 10.783,
        "x1": 9.481,
        "y1": 11.983,
        "name": "Rack108"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 10.783,
        "x1": 11.11,
        "y1": 11.983,
        "name": "Rack109"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 10.783,
        "x1": 12.739,
        "y1": 11.983,
        "name": "Rack110"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-6F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 10.783,
        "x1": 14.368,
        "y1": 11.983,
        "name": "Rack111"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-70",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 10.783,
        "x1": 15.997,
        "y1": 11.983,
        "name": "Rack112"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-71",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 10.783,
        "x1": 17.626,
        "y1": 11.983,
        "name": "Rack113"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-72",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 10.783,
        "x1": 19.255,
        "y1": 11.983,
        "name": "Rack114"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-73",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 10.783,
        "x1": 20.884,
        "y1": 11.983,
        "name": "Rack115"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-74",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 10.783,
        "x1": 22.513,
        "y1": 11.983,
        "name": "Rack116"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-75",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 10.783,
        "x1": 24.142,
        "y1": 11.983,
        "name": "Rack117"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-76",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 10.783,
        "x1": 25.771,
        "y1": 11.983,
        "name": "Rack118"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-77",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 10.783,
        "x1": 27.4,
        "y1": 11.983,
        "name": "Rack119"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-78",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 12.26,
        "x1": 1.336,
        "y1": 13.46,
        "name": "Rack120"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-79",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 12.26,
        "x1": 2.965,
        "y1": 13.46,
        "name": "Rack121"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 12.26,
        "x1": 4.594,
        "y1": 13.46,
        "name": "Rack122"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 12.26,
        "x1": 6.223,
        "y1": 13.46,
        "name": "Rack123"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 12.26,
        "x1": 7.852,
        "y1": 13.46,
        "name": "Rack124"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 12.26,
        "x1": 9.481,
        "y1": 13.46,
        "name": "Rack125"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 12.26,
        "x1": 11.11,
        "y1": 13.46,
        "name": "Rack126"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-7F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 12.26,
        "x1": 12.739,
        "y1": 13.46,
        "name": "Rack127"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-80",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 12.26,
        "x1": 14.368,
        "y1": 13.46,
        "name": "Rack128"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-81",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 12.26,
        "x1": 15.997,
        "y1": 13.46,
        "name": "Rack129"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-82",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 12.26,
        "x1": 17.626,
        "y1": 13.46,
        "name": "Rack130"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-83",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 12.26,
        "x1": 19.255,
        "y1": 13.46,
        "name": "Rack131"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-84",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 12.26,
        "x1": 20.884,
        "y1": 13.46,
        "name": "Rack132"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-85",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 12.26,
        "x1": 22.513,
        "y1": 13.46,
        "name": "Rack133"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-86",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 12.26,
        "x1": 24.142,
        "y1": 13.46,
        "name": "Rack134"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-87",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 12.26,
        "x1": 25.771,
        "y1": 13.46,
        "name": "Rack135"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-88",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 12.26,
        "x1": 27.4,
        "y1": 13.46,
        "name": "Rack136"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-89",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 14.159,
        "x1": 1.336,
        "y1": 15.359,
        "name": "Rack137"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 14.159,
        "x1": 2.965,
        "y1": 15.359,
        "name": "Rack138"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 14.159,
        "x1": 4.594,
        "y1": 15.359,
        "name": "Rack139"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 14.159,
        "x1": 6.223,
        "y1": 15.359,
        "name": "Rack140"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 14.159,
        "x1": 7.852,
        "y1": 15.359,
        "name": "Rack141"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 14.159,
        "x1": 9.481,
        "y1": 15.359,
        "name": "Rack142"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-8F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 14.159,
        "x1": 11.11,
        "y1": 15.359,
        "name": "Rack143"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-90",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 14.159,
        "x1": 12.739,
        "y1": 15.359,
        "name": "Rack144"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-91",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 14.159,
        "x1": 14.368,
        "y1": 15.359,
        "name": "Rack145"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-92",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 14.159,
        "x1": 15.997,
        "y1": 15.359,
        "name": "Rack146"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-93",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 14.159,
        "x1": 17.626,
        "y1": 15.359,
        "name": "Rack147"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-94",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 14.159,
        "x1": 19.255,
        "y1": 15.359,
        "name": "Rack148"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-95",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 14.159,
        "x1": 20.884,
        "y1": 15.359,
        "name": "Rack149"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-96",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 14.159,
        "x1": 22.513,
        "y1": 15.359,
        "name": "Rack150"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-97",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 14.159,
        "x1": 24.142,
        "y1": 15.359,
        "name": "Rack151"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-98",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 14.159,
        "x1": 25.771,
        "y1": 15.359,
        "name": "Rack152"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-99",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 14.159,
        "x1": 27.4,
        "y1": 15.359,
        "name": "Rack153"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9A",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 15.607,
        "x1": 1.336,
        "y1": 16.807,
        "name": "Rack154"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9B",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 15.607,
        "x1": 2.965,
        "y1": 16.807,
        "name": "Rack155"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9C",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 15.607,
        "x1": 4.594,
        "y1": 16.807,
        "name": "Rack156"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9D",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 15.607,
        "x1": 6.223,
        "y1": 16.807,
        "name": "Rack157"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9E",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 15.607,
        "x1": 7.852,
        "y1": 16.807,
        "name": "Rack158"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-9F",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 15.607,
        "x1": 9.481,
        "y1": 16.807,
        "name": "Rack159"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A0",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 15.607,
        "x1": 11.11,
        "y1": 16.807,
        "name": "Rack160"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A1",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 15.607,
        "x1": 12.739,
        "y1": 16.807,
        "name": "Rack161"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A2",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 15.607,
        "x1": 14.368,
        "y1": 16.807,
        "name": "Rack162"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A3",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 15.607,
        "x1": 15.997,
        "y1": 16.807,
        "name": "Rack163"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A4",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 15.607,
        "x1": 17.626,
        "y1": 16.807,
        "name": "Rack164"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A5",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 15.607,
        "x1": 19.255,
        "y1": 16.807,
        "name": "Rack165"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A6",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 15.607,
        "x1": 20.884,
        "y1": 16.807,
        "name": "Rack166"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A7",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 15.607,
        "x1": 22.513,
        "y1": 16.807,
        "name": "Rack167"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A8",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 15.607,
        "x1": 24.142,
        "y1": 16.807,
        "name": "Rack168"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-A9",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 15.607,
        "x1": 25.771,
        "y1": 16.807,
        "name": "Rack169"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AA",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 15.607,
        "x1": 27.4,
        "y1": 16.807,
        "name": "Rack170"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AB",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 17.456,
        "x1": 1.336,
        "y1": 18.656,
        "name": "Rack171"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AC",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 17.456,
        "x1": 2.965,
        "y1": 18.656,
        "name": "Rack172"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AD",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 17.456,
        "x1": 4.594,
        "y1": 18.656,
        "name": "Rack173"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AE",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 17.456,
        "x1": 6.223,
        "y1": 18.656,
        "name": "Rack174"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-AF",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 17.456,
        "x1": 7.852,
        "y1": 18.656,
        "name": "Rack175"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B0",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 17.456,
        "x1": 9.481,
        "y1": 18.656,
        "name": "Rack176"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B1",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 17.456,
        "x1": 11.11,
        "y1": 18.656,
        "name": "Rack177"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B2",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 17.456,
        "x1": 12.739,
        "y1": 18.656,
        "name": "Rack178"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B3",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 17.456,
        "x1": 14.368,
        "y1": 18.656,
        "name": "Rack179"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B4",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 17.456,
        "x1": 15.997,
        "y1": 18.656,
        "name": "Rack180"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B5",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 17.456,
        "x1": 17.626,
        "y1": 18.656,
        "name": "Rack181"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B6",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 17.456,
        "x1": 19.255,
        "y1": 18.656,
        "name": "Rack182"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B7",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 17.456,
        "x1": 20.884,
        "y1": 18.656,
        "name": "Rack183"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B8",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 17.456,
        "x1": 22.513,
        "y1": 18.656,
        "name": "Rack184"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-B9",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 17.456,
        "x1": 24.142,
        "y1": 18.656,
        "name": "Rack185"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BA",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 17.456,
        "x1": 25.771,
        "y1": 18.656,
        "name": "Rack186"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BB",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 17.456,
        "x1": 27.4,
        "y1": 18.656,
        "name": "Rack187"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BC",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 18.984,
        "x1": 1.336,
        "y1": 20.184,
        "name": "Rack188"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BD",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 18.984,
        "x1": 2.965,
        "y1": 20.184,
        "name": "Rack189"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BE",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 18.984,
        "x1": 4.594,
        "y1": 20.184,
        "name": "Rack190"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-BF",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 18.984,
        "x1": 6.223,
        "y1": 20.184,
        "name": "Rack191"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C0",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 18.984,
        "x1": 7.852,
        "y1": 20.184,
        "name": "Rack192"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C1",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 18.984,
        "x1": 9.481,
        "y1": 20.184,
        "name": "Rack193"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C2",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 18.984,
        "x1": 11.11,
        "y1": 20.184,
        "name": "Rack194"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C3",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 18.984,
        "x1": 12.739,
        "y1": 20.184,
        "name": "Rack195"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C4",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 18.984,
        "x1": 14.368,
        "y1": 20.184,
        "name": "Rack196"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C5",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 18.984,
        "x1": 15.997,
        "y1": 20.184,
        "name": "Rack197"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C6",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 18.984,
        "x1": 17.626,
        "y1": 20.184,
        "name": "Rack198"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C7",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 18.984,
        "x1": 19.255,
        "y1": 20.184,
        "name": "Rack199"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C8",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 18.984,
        "x1": 20.884,
        "y1": 20.184,
        "name": "Rack200"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-C9",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 18.984,
        "x1": 22.513,
        "y1": 20.184,
        "name": "Rack201"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CA",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 18.984,
        "x1": 24.142,
        "y1": 20.184,
        "name": "Rack202"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CB",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 18.984,
        "x1": 25.771,
        "y1": 20.184,
        "name": "Rack203"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CC",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 18.984,
        "x1": 27.4,
        "y1": 20.184,
        "name": "Rack204"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CD",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 20.883,
        "x1": 1.336,
        "y1": 22.083,
        "name": "Rack205"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CE",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 20.883,
        "x1": 2.965,
        "y1": 22.083,
        "name": "Rack206"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-CF",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 20.883,
        "x1": 4.594,
        "y1": 22.083,
        "name": "Rack207"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D0",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 20.883,
        "x1": 6.223,
        "y1": 22.083,
        "name": "Rack208"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D1",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 20.883,
        "x1": 7.852,
        "y1": 22.083,
        "name": "Rack209"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D2",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 20.883,
        "x1": 9.481,
        "y1": 22.083,
        "name": "Rack210"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D3",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 20.883,
        "x1": 11.11,
        "y1": 22.083,
        "name": "Rack211"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D4",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 20.883,
        "x1": 12.739,
        "y1": 22.083,
        "name": "Rack212"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D5",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 20.883,
        "x1": 14.368,
        "y1": 22.083,
        "name": "Rack213"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D6",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 20.883,
        "x1": 15.997,
        "y1": 22.083,
        "name": "Rack214"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D7",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 20.883,
        "x1": 17.626,
        "y1": 22.083,
        "name": "Rack215"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D8",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 20.883,
        "x1": 19.255,
        "y1": 22.083,
        "name": "Rack216"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-D9",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 20.883,
        "x1": 20.884,
        "y1": 22.083,
        "name": "Rack217"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DA",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 20.883,
        "x1": 22.513,
        "y1": 22.083,
        "name": "Rack218"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DB",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 20.883,
        "x1": 24.142,
        "y1": 22.083,
        "name": "Rack219"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DC",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 20.883,
        "x1": 25.771,
        "y1": 22.083,
        "name": "Rack220"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DD",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 20.883,
        "x1": 27.4,
        "y1": 22.083,
        "name": "Rack221"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DE",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 0.574,
        "y": 22.31,
        "x1": 1.336,
        "y1": 23.51,
        "name": "Rack222"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-DF",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 2.203,
        "y": 22.31,
        "x1": 2.965,
        "y1": 23.51,
        "name": "Rack223"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E0",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 3.832,
        "y": 22.31,
        "x1": 4.594,
        "y1": 23.51,
        "name": "Rack224"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E1",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 5.461,
        "y": 22.31,
        "x1": 6.223,
        "y1": 23.51,
        "name": "Rack225"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E2",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 7.09,
        "y": 22.31,
        "x1": 7.852,
        "y1": 23.51,
        "name": "Rack226"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E3",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 8.719,
        "y": 22.31,
        "x1": 9.481,
        "y1": 23.51,
        "name": "Rack227"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E4",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 10.348,
        "y": 22.31,
        "x1": 11.11,
        "y1": 23.51,
        "name": "Rack228"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E5",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 11.977,
        "y": 22.31,
        "x1": 12.739,
        "y1": 23.51,
        "name": "Rack229"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E6",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 13.606,
        "y": 22.31,
        "x1": 14.368,
        "y1": 23.51,
        "name": "Rack230"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E7",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 15.235,
        "y": 22.31,
        "x1": 15.997,
        "y1": 23.51,
        "name": "Rack231"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E8",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 16.864,
        "y": 22.31,
        "x1": 17.626,
        "y1": 23.51,
        "name": "Rack232"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-E9",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 18.493,
        "y": 22.31,
        "x1": 19.255,
        "y1": 23.51,
        "name": "Rack233"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-EA",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 20.122,
        "y": 22.31,
        "x1": 20.884,
        "y1": 23.51,
        "name": "Rack234"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-EB",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 21.751,
        "y": 22.31,
        "x1": 22.513,
        "y1": 23.51,
        "name": "Rack235"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-EC",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 23.38,
        "y": 22.31,
        "x1": 24.142,
        "y1": 23.51,
        "name": "Rack236"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-ED",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 25.009,
        "y": 22.31,
        "x1": 25.771,
        "y1": 23.51,
        "name": "Rack237"
    },
    {
        "floor": 4,
        "macid": "5a-c2-15-07-00-EE",
        "capacity": 42,
        "templow": 20,
        "humilow": 40,
        "temphigh": 26,
        "humihigh": 60,
        "energy": 35,
        "x": 26.638,
        "y": 22.31,
        "x1": 27.4,
        "y1": 23.51,
        "name": "Rack238"
    }
]